var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"example-headImg",attrs:{"src":require("../assets/image/example/02-2banner.png")}}),_vm._m(0),_c('div',{staticClass:"head-list"},[_c('div',{staticClass:"head-list-item head-list-item-ischeck",staticStyle:{"margin-left":"26.3rem"},on:{"click":function($event){return _vm.goAssignBlock('block'+ 0,50)}}},[_c('span',{staticClass:"title"},[_vm._v("业务痛点")])]),_c('div',{staticClass:"head-list-item",on:{"click":function($event){return _vm.goAssignBlock('block'+ 1,50)}}},[_c('span',{staticClass:"title"},[_vm._v("解决方案")])]),_c('div',{staticClass:"head-list-item",on:{"click":function($event){return _vm.goAssignBlock('block'+ 2,50)}}},[_c('span',{staticClass:"title"},[_vm._v("方案收益")])])]),_c('div',{ref:"block0",staticClass:"container-1",staticStyle:{"display":"flex"}},[_vm._m(1),_vm._m(2)]),_c('div',{ref:"block1",staticClass:"container-2",staticStyle:{"display":"flex","height":"81rem"}},[_vm._m(3),_vm._m(4)]),_c('div',{ref:"block2",staticClass:"container-1",staticStyle:{"display":"flex","margin-bottom":"10rem"}},[_vm._m(5),_vm._m(6)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"head-explain"},[_c('div',{staticClass:"head-explain-title"},[_vm._v("数字化生产")]),_c('div',{staticClass:"head-explain-content"},[_vm._v("打造透明、高效、稳定的数字化生产管理平台")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase-content-left"},[_c('div',{staticClass:"purchase-title"},[_vm._v("业务痛点")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase-content-right"},[_c('div',{staticClass:"solution-special"},[_vm._v("实现目标：无纸化 > 自动化 > 数字化")]),_c('img',{staticClass:"solution-special-img",attrs:{"src":require("../assets/image/example/02-2业务痛点.svg")}}),_c('div',{staticClass:"purchase-title"},[_vm._v("工厂执行层")]),_c('div',{staticClass:"purchase-content"},[_vm._v("每天手工抄写不同报表及各生产线运转情况，产线数据量大;"),_c('br'),_vm._v(" 问题反馈速率慢，人机、物料等待延时，造成损失;")]),_c('div',{staticClass:"purchase-title"},[_vm._v("工厂管理层")]),_c('div',{staticClass:"purchase-content"},[_vm._v("依赖报表统计及层层上报汇总数据，可能存在些许误差且耗费时间;"),_c('br'),_vm._v(" 无法及时掌握设备停机及故障原因，只能通过层层人工判断做出决策;")]),_c('div',{staticClass:"purchase-title"},[_vm._v("集团管理层")]),_c('div',{staticClass:"purchase-content"},[_vm._v("被动等待各厂滞后的数据组合分析，很难实时获取工厂生产核心指标，进行生产运营决策的优化与预测。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase-content-left"},[_c('div',{staticClass:"purchase-title"},[_vm._v("解决方案")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase-content-right"},[_c('img',{staticClass:"solution-img",attrs:{"src":require("../assets/image/example/02-2解决方案.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase-content-left"},[_c('div',{staticClass:"purchase-title"},[_vm._v("方案收益")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase-content-right"},[_c('div',{staticClass:"digital-box"},[_c('img',{staticClass:"solution-img",attrs:{"src":require("../assets/image/example/02-2方案收益.png")}}),_c('div',[_c('div',{staticClass:"title1"},[_vm._v("工厂执行层")]),_c('div',{staticClass:"title2"},[_vm._v("基于IOT自动采集产线、产程、设备、能耗等数据进行统计记录，实时监控生产状况；")]),_c('div',{staticClass:"title2"},[_vm._v("提供可视化看板及报表工具，建立故障反馈机制，减少人工抄表时间，提高人员效率及设备稳定运行效率。")]),_c('div',{staticClass:"title1",staticStyle:{"margin-top":"4.3rem"}},[_vm._v("工厂管理层")]),_c('div',{staticClass:"title2"},[_vm._v("根据采集数据进行组合分析及可视化展示，实现设备、能耗、人员透明化管理提供，降低管理成本；")]),_c('div',{staticClass:"title2"},[_vm._v("实现产线停机原因分析，建立多维度预警及故障分析体系框架，减少问题发生干预的滞后，提高生产效率。")]),_c('div',{staticClass:"title1",staticStyle:{"margin-top":"4.9rem"}},[_vm._v("集团管理层")]),_c('div',{staticClass:"title2"},[_vm._v("基于数据驱动，结合生产关键指标（ME、订单完成率等）及排序分析，实现生产运营的预测和决策；")]),_c('div',{staticClass:"title2"},[_vm._v("提供集团驾驶舱，精益看板管理，实现运营成本降低及生产力提高。")])])])])
}]

export { render, staticRenderFns }