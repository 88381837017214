<template>
    <div>
        <img class="example-headImg" src="../assets/image/example/02-2banner.png"/>
        <div class="head-explain">
            <div class="head-explain-title">数字化生产</div>
            <div class="head-explain-content">打造透明、高效、稳定的数字化生产管理平台</div>
        </div>
        <div class="head-list">
            <div class="head-list-item head-list-item-ischeck" style="margin-left:26.3rem" @click="goAssignBlock('block'+ 0,50)"><span class="title">业务痛点</span></div>
            <div class="head-list-item" @click="goAssignBlock('block'+ 1,50)"><span class="title">解决方案</span></div>
            <div class="head-list-item" @click="goAssignBlock('block'+ 2,50)"><span class="title">方案收益</span></div>
        </div>
        <div class="container-1" style="display:flex" ref="block0">
            <div class="purchase-content-left">
                <div class="purchase-title">业务痛点</div>
            </div>
            <div class="purchase-content-right">
                <div class="solution-special">实现目标：无纸化 > 自动化 > 数字化</div>
                <img src="../assets/image/example/02-2业务痛点.svg" class="solution-special-img"/>
                <div class="purchase-title">工厂执行层</div>
                <div class="purchase-content">每天手工抄写不同报表及各生产线运转情况，产线数据量大;<br/>
                问题反馈速率慢，人机、物料等待延时，造成损失;</div>
                <div class="purchase-title">工厂管理层</div>
                <div class="purchase-content">依赖报表统计及层层上报汇总数据，可能存在些许误差且耗费时间;<br/>
                无法及时掌握设备停机及故障原因，只能通过层层人工判断做出决策;</div>
                <div class="purchase-title">集团管理层</div>
                <div class="purchase-content">被动等待各厂滞后的数据组合分析，很难实时获取工厂生产核心指标，进行生产运营决策的优化与预测。</div>
            </div>
        </div>
        <div class="container-2" style="display:flex;height: 81rem;" ref="block1">
            <div class="purchase-content-left">
                <div class="purchase-title">解决方案</div>
            </div>
            <div class="purchase-content-right">
                <img class="solution-img" src="../assets/image/example/02-2解决方案.svg"/>
            </div>
        </div>
        <div class="container-1" style="display:flex;margin-bottom: 10rem;" ref="block2">
            <div class="purchase-content-left">
                <div class="purchase-title">方案收益</div>
            </div>
            <div class="purchase-content-right">
                <div class="digital-box">
                    <img class="solution-img" src="../assets/image/example/02-2方案收益.png"/>
                    <div>
                        <div class="title1">工厂执行层</div>
                        <div class="title2">基于IOT自动采集产线、产程、设备、能耗等数据进行统计记录，实时监控生产状况；</div> 
                        <div class="title2">提供可视化看板及报表工具，建立故障反馈机制，减少人工抄表时间，提高人员效率及设备稳定运行效率。</div>
                        <div class="title1" style="margin-top:4.3rem">工厂管理层</div>
                        <div class="title2">根据采集数据进行组合分析及可视化展示，实现设备、能耗、人员透明化管理提供，降低管理成本；</div> 
                        <div class="title2">实现产线停机原因分析，建立多维度预警及故障分析体系框架，减少问题发生干预的滞后，提高生产效率。</div>
                        <div class="title1" style="margin-top:4.9rem">集团管理层</div>
                        <div class="title2">基于数据驱动，结合生产关键指标（ME、订单完成率等）及排序分析，实现生产运营的预测和决策；</div> 
                        <div class="title2">提供集团驾驶舱，精益看板管理，实现运营成本降低及生产力提高。</div>
                    </div>
                    
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    name: 'BizfocusPagesPurchaseCollaboration',
    metaInfo:{
        meta:[
        { name:'keywords', content:'数字化生产,工厂执行层,工厂管理层,集团管理层' },
        { name:'description', content:'打造透明、高效、稳定的数字化生产管理平台。' }
        ]
    },
    data() {
        return {
            navOffsetTop: 0,
        };
    },

    mounted() {
        this.activeIndex = '2'
        this.newsIndex = false
        this.refreshIndexInfo({
            data:this.activeIndex ,
            news:this.newsIndex
        });
        // 监听滚动事件
        window.addEventListener('scroll', this.fiexdNav)
        this.getData()
    },
    destroyed () {
        // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
        window.removeEventListener('scroll', this.fiexdNav)
    },
    methods: {
        ...mapActions([
            'refreshIndexInfo'
        ]),
        goAssignBlock(el, speed) {
        const navHeight = document.querySelector('.head-list').offsetHeight //导航栏的高度
        let _this = this;
        let windowH = window.innerHeight; //浏览器窗口高度
        let h = this.$refs[el].offsetHeight; //模块内容高度
        let t = this.$refs[el].offsetTop - navHeight - speed; //模块相对于内容顶部的距离
        let top = t ; //需要滚动到的位置，若改为 t 则滚动到模块顶部位置，此处是滚动到模块相对于窗口垂直居中的位置
        let scrollTop =
            window.pageYOffset ||
            document.documentElement.scrollTop ||
            document.body.scrollTop; //滚动条距离顶部高度
        let currentTop = scrollTop; //默认滚动位置为当前滚动条位置，若改为0，则每次都会从顶部滚动到指定位置
        let requestId;
        //采用requestAnimationFrame，平滑动画
        function step() {
            //判断让滚动条向上滚还是向下滚
            if (scrollTop < top) {
                if (currentTop <= top) {
                    //   window.scrollTo(x,y) y为上下滚动位置
                    window.scrollTo(0, currentTop);
                    requestId = window.requestAnimationFrame(step);
                } else {
                    window.cancelAnimationFrame(requestId);
                }
                //向下滚动
                currentTop += speed;
            } else {
                if (top <= currentTop) {
                    //注：此处 - speed 是解决居中时存在的问题，可自行设置或去掉
                    window.scrollTo(0, currentTop);
                    requestId = window.requestAnimationFrame(step);
                } else {
                    window.cancelAnimationFrame(requestId);
                }
                //向上滚动
                currentTop -= speed;
            }
        }
        window.requestAnimationFrame(step);
        },
        /** 设置导航条nav到达页面顶部时固定 **/
        // 1.获取导航条nav的offsetTop值，存储在data中（注：之所以不放在滚动事件中，是为了以防添加固定样式后offsetTop值为零,导致页面需要滚动到最上面才可以回到原位）
        getData () {
            this.navOffsetTop = document.querySelector('.head-list').offsetTop + 60
            console.log('this.navOffsetTop',this.navOffsetTop)
        },
        fiexdNav () {
            const navHeight = document.querySelector('.head-list').offsetHeight //导航栏的高度
            // 2.获取当前页面的卷曲高度
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
            const nav = document.querySelector('.head-list')
            // 3.判断卷曲高度是否大于等于导航条的offsetTop值
            if (scrollTop > this.navOffsetTop) {
                //   3.1若满足，则给nav导航添加固定样式
                nav.classList.add('fixedNav')
            } else {
                //   3.2若不满足，则删除nav导航的固定样式
                nav.classList.remove('fixedNav')
            }

            /** 当滚动到一定区域时给导航项添加选中样式 **/
            //  1.获取所有锚点元素
            // 2.获取锚点元素的offsetTop值，并收集在一个数组
            const contentsOffsetTop = []
            // contents.forEach(item => {
            //     contentsOffsetTop.push(item.offsetTop)
            //     console.log('2222',item.offsetTop)
            // })
            contentsOffsetTop.push(this.$refs['block0'].offsetTop)
            contentsOffsetTop.push(this.$refs['block1'].offsetTop)
            contentsOffsetTop.push(this.$refs['block2'].offsetTop)
            // console.log('contentsOffsetTop',contentsOffsetTop)
            // console.log('contentsOffsetTop',contentsOffsetTop)
            // 3.获取页面高度
            const pageHeight = window.innerHeight
            // 4.获取nav的子元素
            const navChildren = document.querySelectorAll('.head-list .head-list-item')
            for (let j = 0; j < contentsOffsetTop.length; j++) {
                    navChildren[j].classList.remove('head-list-item-ischeck')
                    navChildren[0].classList.add('head-list-item-ischeck')
                }
            if(scrollTop>contentsOffsetTop[0] + this.$refs['block0'].offsetHeight/2){
                for (let j = 0; j < contentsOffsetTop.length; j++) {
                    navChildren[j].classList.remove('head-list-item-ischeck')
                    navChildren[1].classList.add('head-list-item-ischeck')
                }
            }
            if(scrollTop>contentsOffsetTop[1] + this.$refs['block1'].offsetHeight/2){
                for (let j = 0; j < contentsOffsetTop.length; j++) {
                    navChildren[j].classList.remove('head-list-item-ischeck')
                    navChildren[2].classList.add('head-list-item-ischeck')
                }
            }
        },
        /**
         *设置点击导航跳转到指定选择器对应的锚点元素
        * @param {*} selector
        **/
        skipTo (selector) {
            const navHeight = document.querySelector('.head-list-item').offsetHeight
            // scrollIntoView() js原生方法，实现锚点滚动过渡
            const target = document.querySelector(selector)
            target.scrollIntoView({ behavior: 'smooth' })
            // scrollTo() 把内容滚动到指定的坐标。减去导航高度的目的：导航用定位固定在顶部，如果不减去，导航栏会遮挡一部分内容
            window.scrollTo(0, target.offsetTop - navHeight)
        }
    },
};
</script>

<style lang="less" scoped>
.purchase-content-left{
    width:33rem;
    text-align: justify;
    margin-left: 26rem;
    margin-top: 5.9rem;

}
.purchase-title{
    height: 2rem;
    font-size: 2rem;
    font-family: 'CN_Medium';
    font-weight: 500;
    color: #000000;
    line-height: 3rem;
}
.purchase-content{
    height: 7.7rem;
    font-size: 1.6rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #666666;
    line-height: 2.6rem;

    margin: 1.3rem 0 0 0.2rem;
}
.purchase-content-right{
    text-align: justify;
    margin-top: 5.9rem;
    padding-bottom: 2rem;
}
.solution-img{
    width: 107.2rem;
    height: 69.1rem;
}
.benefits-img{
    width: 106.3rem;
    height: 66.2rem;
}
.solution-special{
    margin: 0 0 0 35.6rem;
    height: 1.6rem;
    font-size: 1.6rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #11A84F;
    line-height: 2.4rem;
}
.solution-special-img{
    width: 89.1rem;
    height: 1.1rem;
    margin: 1.2rem 0 6.1rem 6.4rem;
}
.digital-box{
    display: flex;
    margin-bottom: 10rem;
    img{
        width: 53.6rem;
        height: 54rem;
    }
    .title1{
        height: 2rem;
        font-size: 2rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #11A84F;
        line-height: 3rem;

        margin-left: 6.8rem;
    }
    .title2{
        width: 48rem;
        height: 4.8rem;
        font-size: 1.6rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #666666;
        line-height: 2.4rem;
        margin-left: 6.8rem;
        margin-top: 1rem;
    }
}
</style>
